'use strict';

const _ = require('../../../utils/under-dash');

const utils = require('../../../utils/utils');
const colCache = require('../../../utils/col-cache');
const XmlStream = require('../../../utils/xml-stream');

const BaseXform = require('../base-xform');
const StaticXform = require('../static-xform');
const ListXform = require('../list-xform');
const DefinedNameXform = require('./defined-name-xform');
const SheetXform = require('./sheet-xform');
const WorkbookViewXform = require('./workbook-view-xform');
const WorkbookPropertiesXform = require('./workbook-properties-xform');

var WorkbookXform = (module.exports = function() {
  this.map = {
    fileVersion: WorkbookXform.STATIC_XFORMS.fileVersion,
    workbookPr: new WorkbookPropertiesXform(),
    bookViews: new ListXform({ tag: 'bookViews', count: false, childXform: new WorkbookViewXform() }),
    sheets: new ListXform({ tag: 'sheets', count: false, childXform: new SheetXform() }),
    definedNames: new ListXform({ tag: 'definedNames', count: false, childXform: new DefinedNameXform() }),
    calcPr: WorkbookXform.STATIC_XFORMS.calcPr,
  };
});

utils.inherits(
  WorkbookXform,
  BaseXform,
  {
    WORKBOOK_ATTRIBUTES: {
      xmlns: 'http://schemas.openxmlformats.org/spreadsheetml/2006/main',
      'xmlns:r': 'http://schemas.openxmlformats.org/officeDocument/2006/relationships',
      'xmlns:mc': 'http://schemas.openxmlformats.org/markup-compatibility/2006',
      'mc:Ignorable': 'x15',
      'xmlns:x15': 'http://schemas.microsoft.com/office/spreadsheetml/2010/11/main',
    },
    STATIC_XFORMS: {
      fileVersion: new StaticXform({ tag: 'fileVersion', $: { appName: 'xl', lastEdited: 5, lowestEdited: 5, rupBuild: 9303 } }),
      calcPr: new StaticXform({ tag: 'calcPr', $: { calcId: 171027 } }),
    },
  },
  {
    prepare(model) {
      model.sheets = model.worksheets;

      // collate all the print areas from all of the sheets and add them to the defined names
      const printAreas = [];
      let index = 0; // sheets is sparse array - calc index manually
      model.sheets.forEach(sheet => {
        if (sheet.pageSetup && sheet.pageSetup.printArea) {
          const definedName = {
            name: '_xlnm.Print_Area',
            ranges: [`${sheet.name}!${sheet.pageSetup.printArea}`],
            localSheetId: index,
          };
          printAreas.push(definedName);
        }
        if (sheet.pageSetup && (sheet.pageSetup.printTitlesRow || sheet.pageSetup.printTitlesColumn)) {
          const ranges = [];

          if (sheet.pageSetup.printTitlesColumn) {
            const titlesColumns = sheet.pageSetup.printTitlesColumn.split(':');
            ranges.push(`'${sheet.name}'!$${titlesColumns[0]}:$${titlesColumns[1]}`);
          }

          if (sheet.pageSetup.printTitlesRow) {
            const titlesRows = sheet.pageSetup.printTitlesRow.split(':');
            ranges.push(`'${sheet.name}'!$${titlesRows[0]}:$${titlesRows[1]}`);
          }

          const definedName = {
            name: '_xlnm.Print_Titles',
            ranges,
            localSheetId: index,
          };

          printAreas.push(definedName);
        }
        index++;
      });
      if (printAreas.length) {
        model.definedNames = model.definedNames.concat(printAreas);
      }

      model.media &&
        model.media.forEach((medium, i) => {
          // assign name
          medium.name = medium.type + (i + 1);
        });
    },

    render(xmlStream, model) {
      xmlStream.openXml(XmlStream.StdDocAttributes);
      xmlStream.openNode('workbook', WorkbookXform.WORKBOOK_ATTRIBUTES);

      this.map.fileVersion.render(xmlStream);
      this.map.workbookPr.render(xmlStream, model.properties);
      this.map.bookViews.render(xmlStream, model.views);
      this.map.sheets.render(xmlStream, model.sheets);
      this.map.definedNames.render(xmlStream, model.definedNames);
      this.map.calcPr.render(xmlStream);

      xmlStream.closeNode();
    },

    parseOpen(node) {
      if (this.parser) {
        this.parser.parseOpen(node);
        return true;
      }
      switch (node.name) {
        case 'workbook':
          return true;
        default:
          this.parser = this.map[node.name];
          if (this.parser) {
            this.parser.parseOpen(node);
          }
          return true;
      }
    },
    parseText(text) {
      if (this.parser) {
        this.parser.parseText(text);
      }
    },
    parseClose(name) {
      if (this.parser) {
        if (!this.parser.parseClose(name)) {
          this.parser = undefined;
        }
        return true;
      }
      switch (name) {
        case 'workbook':
          this.model = {
            sheets: this.map.sheets.model,
            properties: this.map.workbookPr.model || {},
            views: this.map.bookViews.model,
          };
          if (this.map.definedNames.model) {
            this.model.definedNames = this.map.definedNames.model;
          }

          return false;
        default:
          // not quite sure how we get here!
          return true;
      }
    },

    reconcile(model) {
      const rels = (model.workbookRels || []).reduce((map, rel) => {
        map[rel.Id] = rel;
        return map;
      }, {});

      // reconcile sheet ids, rIds and names
      const worksheets = [];
      let worksheet;
      let index = 0;

      (model.sheets || []).forEach(sheet => {
        const rel = rels[sheet.rId];
        if (!rel) {
          return;
        }
        worksheet = model.worksheetHash[`xl/${rel.Target}`];
        // If there are "chartsheets" in the file, rel.Target will
        // come out as chartsheets/sheet1.xml or similar here, and
        // that won't be in model.worksheetHash.
        // As we don't have the infrastructure to support chartsheets,
        // we will ignore them for now:
        if (worksheet) {
          worksheet.name = sheet.name;
          worksheet.id = sheet.id;
          worksheet.state = sheet.state;
          worksheets[index++] = worksheet;
        }
      });

      // reconcile print areas
      const definedNames = [];
      _.each(model.definedNames, definedName => {
        if (definedName.name === '_xlnm.Print_Area') {
          worksheet = worksheets[definedName.localSheetId];
          if (worksheet) {
            if (!worksheet.pageSetup) {
              worksheet.pageSetup = {};
            }
            const range = colCache.decodeEx(definedName.ranges[0]);
            worksheet.pageSetup.printArea = range.dimensions;
          }
        } else if (definedName.name === '_xlnm.Print_Titles') {
          worksheet = worksheets[definedName.localSheetId];
          if (worksheet) {
            if (!worksheet.pageSetup) {
              worksheet.pageSetup = {};
            }

            const rangeString = definedName.ranges.join(',');

            const rowRangeRegex = /\$\d+:\$\d+/;
            const rowRangeMatches = rangeString.match(rowRangeRegex);

            if (rowRangeMatches && rowRangeMatches.length) {
              worksheet.pageSetup.printTitlesRow = rowRangeMatches[0];
            }

            const columnRangeRegex = /\$[A-Z]+:\$[A-Z]+/;
            const columnRangeMatches = rangeString.match(columnRangeRegex);

            if (columnRangeMatches && columnRangeMatches.length) {
              worksheet.pageSetup.printTitlesColumn = columnRangeMatches[0];
            }
          }
        } else {
          definedNames.push(definedName);
        }
      });
      model.definedNames = definedNames;

      // used by sheets to build their image models
      model.media.forEach((media, i) => {
        media.index = i;
      });
    },
  }
);
